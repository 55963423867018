@import url('https://fonts.googleapis.com/css2?family=Maitree:wght@200;300;400;500;600;700&family=Martel+Sans:wght@200;300;400;600;700;800;900&display=swap');

.sp__header {
    display: flex;
    flex-direction: row;
    align-items: row;
    top: 0px;
    left: 0px;
    position: relative;
}

.sp__header-left {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #C19D23;
}

.sp__header-left img {
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 500px;
}

.sp__header-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F8F8F8;
}

.sp__header-right__top {
    flex: .5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sp__header-right h2 {
    font-weight: 600;
    font-family: 'Maitree', serif;
    font-size: 48px;
    color: #000;
}

.sp__header-right h1 {
    font-weight: 800;
    font-family: var(--font-family);
    font-size: 56px;
    color: #000;
}

.sp__header-right p {
    font-size: 20px;
    font-weight: 500;
    font-family: "Maitree", serif;
    color: #000;

    justify-self: center;
    padding: 0 2rem;
    margin-top: 40px;

    text-align: center;
    letter-spacing: 0.045em;
    text-transform: capitalize;
}

.sp__header-right button {
    margin: 25px 0;
    padding: 0.5rem 1rem;
    color: #fff;
    background: #008080;
    font-family: var(--font-family);
    font-weight: 900;
    font-size: 20px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 79.5px;
}

@media screen and (max-width: 1050px){
    .sp__header-left img{
        max-width: 100%;
        max-height: 100%;
    }

    .sp__header-left h2 {
        font-size: 40px;
    }   

    .sp__header-right h1 {
        font-size: 48px;
    } 

    .sp__header-right p {
        font-size: 20px;
        margin-top: 15px;
    }
}

@media screen and (max-width: 750px){
    .sp__header {
        flex-direction: column;
    }

    .sp__header-right {
        margin-top: 20px;
    }
}
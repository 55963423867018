@import url('https://fonts.googleapis.com/css2?family=Maitree:wght@200;300;400;500;600;700&family=Martel+Sans:wght@200;300;400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.sp__skills {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    max-width: 100%;
    padding: 2rem;
}

.sp__skills-title {
    font-size: 36px;
    font-family: "Maitree", sans-serif;
    align-self: center;

    letter-spacing: .05rem;
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-color: #FEC400;
    text-decoration-thickness: 2px;
    text-underline-offset: 5px;
}

.sp__skills-lists {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.sp__skills-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}


.sp__skills-element__title div {
    width: 37px;
    height: 37px;
    display: flex;
    background: linear-gradient(270deg, #008080 47.5%, rgba(196, 196, 196, 0) 50%);
    border: 4px solid #008080;
    border-radius: 50%;
}

.sp__skills-element__title {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.sp__skills-element__title h4{
    align-items: flex-start;
    flex: 1;
    font-size: 24px;
    font-family: "Maitree", sans-serif;
    margin-left: 10px;

    letter-spacing: .05rem;
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-color: #008080;
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
}

.sp__skills-element__content {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
    margin: 20px 0;
}

.sp__skills-element__content li{
    display: flex;
    max-width: 100%;
    flex-direction: column;
    margin: 20px 0;
    font-family: var(--font-family);
    font-size: 18px;
}

.sp__skills-element__content li h5 {
    flex: 1;
    margin-bottom: 5px;
}

.sp__skills-element__content li div {
    align-items: center;
    height: 20px;
    width: 350px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border: 2px solid black;
    border-radius: 25px;
    font-size: 14px;
}

.sp__skills-element__content90 div {
    background: linear-gradient(90deg, #008080 90%, #fff 10%);
}

.sp__skills-element__content85 div {
    background: linear-gradient(90deg, #008080 85%, #fff 15%);
}

.sp__skills-element__content80 div {
    background: linear-gradient(90deg, #008080 80%, #fff 20%);
}

.sp__skills-element__content75 div {
    background: linear-gradient(90deg, #008080 75%, #fff 25%);
}

.sp__skills-element__content li div p {
    padding: 5px;
}

@media screen  and (min-width: 2000px) {
    .sp__skills-element__content li div {
        width: 800px;
    }
}

@media screen  and (min-width: 1600px) and (max-width: 2000px){
    .sp__skills-element__content li div {
        width: 650px;
    }
}

@media screen  and (min-width: 950px) and (max-width: 1050px) {
    .sp__skills-element__content li div {
        width: 300px;
        font-size: 10px;
    }
}

@media screen  and (max-width: 950px) {
    .sp__skills-element__content li div {
        width: 225px;
        font-size: 8px;
    }
}

@media screen  and (max-width: 850px) { 
    .sp__skills-lists {
        flex-direction: column;
        padding-bottom: 1rem;
    }

    .sp__skills-element {
        margin-bottom: 20px;
    }

    .sp__skills-element__content li div {
        width: 450px;
    }

    .other-concepts {
        display: none;
    }
}

@media screen  and (max-width: 550px) {
    .sp__skills-title {
        font-size: 24px;
    }

    .sp__skills-element__title h4 {
        font-size: 20px;
    }

    .sp__skills-element__content li div {
        width: 400px;
        font-size: 10px;
    }
}

@media screen  and (max-width: 450px) {
    .sp__skills-title {
        font-size: 24px;
    }

    .sp__skills-element__title h4 {
        font-size: 20px;
    }

    .sp__skills-element__content li div {
        width: 300px;
        font-size: 10px;
    }
}

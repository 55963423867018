@import url('https://fonts.googleapis.com/css2?family=Maitree:wght@200;300;400;500;600;700&family=Martel+Sans:wght@200;300;400;600;700;800;900&display=swap');

.sp__footer {
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1rem;
}

.sp__footer-socials {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
}

.sp__footer-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sp__footer-copyright {
    font-family: "Maitree", sans-serif;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.sp__footer-socials img {
    max-width: 30px;
    max-height: 30px;
}

.sp__footer-copyright p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    border: 2px solid white;
    border-radius: 50%;
    padding: 2px;
    margin: 5px;
}

.sp__footer-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
}

.sp__footer-links p {
    font-family: "Maitree", sans-serif;
    color: #fff;
    font-weight: 500;
    font-size: 16px;

    margin: 0 .5rem;
    cursor: pointer;
}

@media screen and (max-width: 450px) {
    .sp__footer-copyright {
        font-size: 12px;
    }
}
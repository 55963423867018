@import url('https://fonts.googleapis.com/css2?family=Maitree:wght@200;300;400;500;600;700&family=Martel+Sans:wght@200;300;400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.sp__projects {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sp__projects-title {
    align-self: center;
    font-family: "Maitree", sans-serif;

    letter-spacing: .05rem;
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-color: #FEC400;
    text-decoration-thickness: 2px;
    text-underline-offset: 10px;
}

.sp__project-list {
    flex: 1;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 4rem;
}

.sp__project {
    width: 300px;
    border-top: 2px solid #008080;
    border-radius: 10px;
    
}

.sp__project:hover {
    background: #F8F8F8;
    box-shadow: -7px 33px 23px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;
}

.sp__project:hover .sp__project-info__link img {
    display: block;
}

.sp__project-info {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}

.sp__project-info h4 {
    font-family: var(--font-family);
    padding: .5rem 0;
}

.sp__project-info p {
    font-family: "Maitree", sans-serif;
    max-width: 225px;
    padding: .25rem 0;
    font-size: 13px;
}

.sp__project-info__link img{
    max-height: 50px;
    max-width: 50px;
    margin-right: 10px;
    display: none;
}

@media screen and (max-width: 1150px) {
    .sp__project {
        width: 250px;
    }
}

@media screen and (max-width: 975px) {
    .sp__project {
        width: 200px;
    }
}

@media screen and (max-width: 850px) {
    .sp__projects {
        padding-bottom: 2rem;
    }

    .sp__project-list {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .sp__project {
        width: 500px;
        max-width: 100%;
    }

    .sp__project {
        margin: 1rem;
    }

    .sp__project-list2 {
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .sp__project {
        width: 400px;
    }
}

@media screen and (max-width: 450px) {
    .sp__project {
        width: 300px;
    }

    .sp__projects-title {
        font-size: 12px;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Maitree:wght@200;300;400;500;600;700&family=Martel+Sans:wght@200;300;400;600;700;800;900&display=swap');

.sp__top {
    background: linear-gradient(to left, #F8F8F8 50%, #C19D23 50%);
    height: 120px;
    top: 0;
    padding-top: 1rem;
}

.sp__navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    max-height: 75px;

    margin: 0rem 8rem;
    background: #F8F8F8;
    
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 79.5px;
}

.sp__navbar-logo {
    justify-content: flex-start;
    font-family:  var(--font-family);
    font-weight: 400;
    margin: .5rem;
    margin-left: 1rem;
    padding: .25rem;

    color: #000;
    text-decoration: underline;
    text-decoration-color: #008080;
    text-underline-offset: 4px;
    cursor: default;
}

.sp__navbar-links {
    display: flex;
    align-items: center;
    padding: .25rem;
}

.sp__navbar-links p {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 24px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
    padding: .25rem .25rem;
}

.sp__navbar-menu_container p {
    font-family: 'Martel Sans', serif;
    color: #000000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 24px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
    padding: .75rem 0.25rem;
}

.sp__navbar-links p a {
    padding: 0.25rem;
}

.sp__navbar-links p a:hover{
    background-color: #008080;
    color: #fff;
    border-radius: 5px;
}

.sp__navbar-contact {
    justify-content: flex-end;
    margin: .5rem;
    margin-right: 1rem;
}

.sp__navbar-contact button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #008080;
    font-family: var(--font-family);
    font-weight: 900;
    font-size: 20px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 79.5px;
}

.sp__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #008080;
    font-family: var(--font-family);
    font-weight: 900;
    font-size: 20px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.sp__navbar-welcome {
    display: none;
}

.sp__navbar-menu {
    margin-left: 1rem;
    margin-right: 1rem;
    top: 0px;

    display: none;
    position: relative;
}

.sp__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    z-index: 999999;
    text-align: end;
    background: #C19D23;
    color: #fff;
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.sp__navbar-menu_container p {
    margin: 1rem 0;
    color: #fff;
}

.sp__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    .sp__navbar-links {
        display: none;
    }

    .sp__navbar-contact {
        display: none;
    }

    .sp__navbar-welcome {
        display: block;
        font-size: 20px;
        font-family: "Maitree", serif;
        color: #008080;
    }

    .sp__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .sp__navbar {
        margin: .5rem 4rem;
    }
}

@media screen and (max-width: 750px) {
    .sp__top {
        background: #C19D23;
    }

    .sp__navbar-menu_container {
        background: white;
    }

    .sp__navbar-menu_container p {
        color: #008080;
    }
}

@media screen and (max-width: 550px) {
    .sp__navbar {
        margin: .25rem 2rem;
    }

    .sp__navbar-contact {
        display: none;
    }

    .sp__navbar-menu_container-links-contact {
        display: block;
    }
}